import AuthTypes from "./auth.types";
import { FETCH, API, FETCH2 } from '../../util/api';
import { selectUserAccessToken } from "./auth.selector";
import { setUserAccessToken } from "../../util/helper";

export const getUserDetails = (mobile) => {
return dispatch => {
    console.log('auth mobille', mobile);
    dispatch({
        type: AuthTypes.LOGIN_START
    })

    const form = new FormData();
    form.append('client_mobile', `${mobile}`)
    form.append('client_name', '')

    FETCH({
        method: 'POST',
        url: API.GET_USER_DETAILS,
        body: JSON.stringify({
            'client_mobile' : `${mobile}`,
            'client_name': 'testing'
        })
      })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        if (resData.data) {
            dispatch({
                type: AuthTypes.LOGIN_SUCCESS,
                payload: resData.data,
              });
        }
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: AuthTypes.LOGIN_FAILED });
      });
}
}

/**
 * To check if the user exists in any organisation
 * @memberof OrganizationActions
 * @method checkUserExistence
 * @param {number} userMobile
 * @returns An object of user details
 */

 export const checkUserExistence = (userEmail) => {
  return (dispatch) => {
    dispatch({ type: AuthTypes.CHECK_USER_EXISTENCE_START });
    console.log("userExistence called")
    FETCH({
      method: "GET",
      // url: API.CHECK_USER_EXISTENCE + `?user_mobile=${userMobile}`,
      url: API.CHECK_USER_EXISTENCE + `?user_email=${userEmail}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        dispatch({
          type: AuthTypes.CHECK_USER_EXISTENCE_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: AuthTypes.CHECK_USER_EXISTENCE_FAILURE });
      });
  };
};

export const userCreditHistory = workspace_id => {
  return dispatch => {
    dispatch({ type: AuthTypes.USER_CREDIT_HISTORY_START });
    FETCH({
      method: "GET",
      url: API.USER_CREDIT_HISTORY + `?workspace_id=${workspace_id}`
    })
    .then(res => {
      return res.json();
    })
    .then(res => {
      dispatch({
        type: AuthTypes.USER_CREDIT_HISTORY_SUCCESS,
        payload: res
      })
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: AuthTypes.USER_CREDIT_HISTORY_FAILURE })
    })
  }
}

export const orgCreditHistory = org_id => {
  return dispatch => {
    dispatch({ type: AuthTypes.ORG_CREDIT_HISTORY_START });
    FETCH({
      method: "GET",
      url: API.ORG_CREDIT_HISTORY + `?org_id=${org_id}`
    })
    .then(res => {
      return res.json();
    })
    .then(res => {
      dispatch({
        type: AuthTypes.ORG_CREDIT_HISTORY_SUCCESS,
        payload: res
      })
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: AuthTypes.ORG_CREDIT_HISTORY_FAILURE })
    })
  }
}

export const getPlanList = () => {
  return dispatch => {
    dispatch({ type: AuthTypes.GET_PLAN_LIST_START });
    FETCH({
      method: "GET",
      url: API.PLAN_LIST
    })
    .then(res => res.json())
    .then(res => {
      dispatch({
        type: AuthTypes.GET_PLAN_LIST_SUCCESS,
        payload: res
      })
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: AuthTypes.GET_PLAN_LIST_FAILURE });
    })
  }
}

export const getUserProfileData = () => {
  return dispatch => {
    dispatch({ type: AuthTypes.GET_USER_PROFILE_DATA_START });

    const workspaceId = localStorage.getItem("workspaceId");

    FETCH({
      method: 'GET',
      url: `${API.USER_PROFILE_DATA}?workspace_id=${workspaceId}`
    })
    .then(res => res.json())
    .then(res => {
      console.log("respomse", res)
      dispatch({
        type: AuthTypes.GET_USER_PROFILE_DATA_SUCCESS,
        payload: res.data
      })
    })
    .catch(error => {
      dispatch({
        type: AuthTypes.GET_USER_PROFILE_DATA_FAILURE,
        payload: error
      })
    })
  }
}

export const getUserTags = () => {
  return dispatch => {
    dispatch({ type: AuthTypes.GET_USER_TAGS_START });

    const orgId = localStorage.getItem("currentOrgId");

    FETCH({
      method: 'GET',
      url: `${API.USER_TAGS}?org_id=${orgId}`
    })
    .then(res => res.json())
    .then(res => {
      console.log("respomse", res)
      dispatch({
        type: AuthTypes.GET_USER_TAGS_SUCCESS,
        payload: res.data
      })
    })
    .catch(error => {
      dispatch({
        type: AuthTypes.GET_USER_TAGS_FAILURE,
        payload: error
      })
    })
  }
}

export const getTeamAddonList = () => {
  return dispatch => {
    dispatch({ type: AuthTypes.GET_TEAM_ADDON_LIST_START })

    FETCH({
      method: 'GET',
      url: API.GET_TEAM_ADDON_LIST
    })
    .then(res => res.json())
    .then(res => {
      dispatch({
        type: AuthTypes.GET_TEAM_ADDON_LIST_SUCCESS,
        payload: res.data
      })
    })
    .catch(error => {
      dispatch({
        type: AuthTypes.GET_PLAN_LIST_FAILURE,
        payload: error
      })
    })
  }
}

export const getUserDataV2 = () => {
  return (dispatch, getState) => {
    dispatch({ type: AuthTypes.GET_USER_DATA_V2_START })

    const userAuthData = getState()?.auth?.userExistenceData?.data;

   

    const mobile = userAuthData?.mobile;
    const email = userAuthData?.email;

    console.log("getUserDataV2", {
      userAuthData,
      mobile, 
      email
    });

    FETCH2({
      method: 'GET',
      url: `${API.GET_USER_DATA_V2}?mobile=${mobile}${email ? `&email=${email}` : ''}`
    })
    .then(res => res.json())
    .then( async res => {
  

      const tempUserAccessToken = getUserAccesTokenFromRes(res);
      await setUserAccessToken(tempUserAccessToken);

      console.log("getUserDataV2", {
        res,
        tempUserAccessToken,
      });
      dispatch({
        type: AuthTypes.GET_USER_DATA_V2_SUCCESS,
        payload: res
      })
      dispatch({
        type: AuthTypes.GET_USER_ACCESS_TOKEN_SUCCESS,
        payload: tempUserAccessToken
      })

    })
    .catch(error => {
      dispatch({
        type: AuthTypes.GET_USER_DATA_V2_FAILURE,
        payload: error
      })
    })
  }
}


const getUserAccesTokenFromRes =(auth)=> {
  try {

    const { accounts, active_account } = auth;
    console.log("selectUserAccessToken", {auth, accounts, active_account})
    if (!accounts || !Array.isArray(accounts) || !accounts.length || !active_account) {
      return null;
    }
    const finalData = accounts.find(account => account.email === active_account.email) || null;

    return finalData?.access_token
  }
  catch(err){
    console.error("get User Access token from res failed",err);
    return null;
  }
}

// export const fetchAccessTokenIfNeeded = () => async (dispatch, getState) => {
//   console.log("fetchAccessTokenIfNeeded entered",);
//   const state = getState();
//   const accessToken = selectUserAccessToken(state);

//   console.log("fetchAccessTokenIfNeeded",{accessToken});

//   if (accessToken === null) {
//      getUserDataV2();
//     fetchAccessTokenIfNeeded()
//   }
//   else return accessToken;
// };


export const logoutUser = () => dispatch => dispatch({type: AuthTypes.LOGOUT});

export const updateShowDashboardOnly = (flag) => {
  return dispatch => {
     
      
      try {
        console.log('updateShowDashboardOnly', flag);
        dispatch({
            type: AuthTypes.UPDATE_SHOW_DASHBOARD_ONLY_START,
        })
        
        dispatch({
          type: AuthTypes.UPDATE_SHOW_DASHBOARD_ONLY_SUCCESS,
          payload: flag,
        });
      } catch (error) {
          console.error(error);
          dispatch({ type: AuthTypes.UPDATE_SHOW_DASHBOARD_ONLY_FAILED });
      }
      

  }
  }