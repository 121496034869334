import React, { Suspense, lazy, useEffect, useState } from 'react';
import "./App.scss";
// import LandingPage from "./containers/landing";

import { Route, Switch } from "react-router-dom";
import publicRoutes, { salesDashboardRoutes } from "./routes";
import AuthMiddleware from "./routes/route";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { getLoggedInUserWorkspaceId } from './util/helper';
import { initializeGlitchTip } from './util/sentry';
import i18n from './i18n';
import EazybeLoader from './components/loader';
// import PublicRoutesWithSuspense from './routes';
///Some styles were dependent on below files, so importing in app so that
// styles doesn't break 
//***start */
import '../src/containers/landing.css';
import '../src/components/Spline-chart/spline-chart.styles.scss'
import { selectShowDashboardOnly } from './redux/auth/auth.selector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
///Some styles were dependent on below files, so importing in app so that
// styles doesn't break 
//***end***/
ReactGA.initialize('UA-207023293-1');

const Terms = lazy(() => import("./containers/Terms"));
const Privacy = lazy(() => import("./containers/Privacy"));
const Faqs = lazy(() => import("./containers/Faqs"));
const Home = lazy(() => import("./containers/Home"));
const Checkout = lazy(() => import("./containers/Checkout"));
const Feedback = lazy(() => import("./containers/Feedback"));
const Features = lazy(() => import("./containers/Features"));
const HowItWorks = lazy(() => import("./containers/HowItWorks"));
const SignupWorkSpace = lazy(() => import("./containers/SignupWorkSpace/SignupWorkspace"));
const OnboardingScreen = lazy(() => import("./containers/OnboardingScreen/OnboardingScreen"));
const SelectSectionComp = lazy(() => import("./containers/OnboardingScreen/ChooseOptionComp/ChooseOption"));
const TrendsTable = lazy(() => import("./pages/quickreplies/analysis/TrendsTable/TrendsTable"));
const PerformanceTable = lazy(() => import("./pages/quickreplies/analysis/PerformanceTable/PerformanceTable"));
const CreateOrganization = lazy(() => import("./containers/CreateOrganization/CreateOrganization"));

ReactGA.initialize('UA-207023293-1');

function App({showDashboardOnlyData}) {
  const languagesPresent = ['en', 'es', 'hi', 'id', 'pt']
  React.useEffect(() => {
    let preferredLanguage = localStorage.getItem("preferredLanguage");
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage);
      // i18n.changeLanguage("en");
    } else {
      let language = navigator.language.slice(0, 2);
      const languagesPresent = ['en', 'es', 'hi', 'id', 'pt']
      if (languagesPresent.includes(language)) {
        i18n.changeLanguage(language);
        // i18n.changeLanguage("en");
      } else {
        i18n.changeLanguage("en");
      }
    }

    const rootItem = document.getElementById('root');
    let activeToast = localStorage.getItem("active_toast");
    let toastType = localStorage.getItem("toast_type");

    rootItem.addEventListener("show_toast", () => {
      activeToast = localStorage.getItem("active_toast");
      toastType = localStorage.getItem("toast_type");

      if (activeToast !== "null") {
        notify(activeToast, toastType);
        localStorage.setItem("active_toast", "null");
      }
    });
  }, []);

  useEffect(() => {
    const tempWorkspaceId = getLoggedInUserWorkspaceId();
    if (tempWorkspaceId) {
      initializeGlitchTip();
    }
  }, []);

  const notify = (message, toastType) => {
    if (toastType === "success") {
      toast.success(message);
      localStorage.setItem("toast_type", null);
    } else if (toastType === "warning") {
      toast.warning(message);
      localStorage.setItem("toast_type", null);
    } else if (toastType === "error") {
      toast.error(message);
      localStorage.setItem("toast_type", null);
    } else {
      toast(message);
    }
  };

  const [newRoute, setNewRoute] = useState(publicRoutes);
  useEffect(() => {
    // const getItem = localStorage.getItem('pathParams'); 
    console.log('App getItem', showDashboardOnlyData);
    // if (getItem) {
    //   const getItemData = JSON.parse(getItem);
    //   console.log('getItem app.js 2', getItemData);
      
    //   const showDashboardOnly = getItemData.showDashboardOnly === 'true';

      if (showDashboardOnlyData) {
        setNewRoute(salesDashboardRoutes);
      } else {
        setNewRoute(publicRoutes);
      }
    // }
    console.log('getItem app.js 3', newRoute);
  }, [showDashboardOnlyData]);

  

  return (
    <div>
      <Suspense  fallback={<EazybeLoader style={{height: "100vh", width : "100vw"}}/>}>
        <Switch>
          <Route path="/terms" exact component={Terms} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/faqs" exact component={Faqs} />
          <Route path="/pricing" exact component={Home} />
          <Route path="/features" exact component={Features} />
          <Route path="/howitworks" exact component={HowItWorks} />
          <Route path="/feedback/:id" exact component={Feedback} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/workspace/signup" exact component={SignupWorkSpace} />
          <Route path="/onboarding" exact component={OnboardingScreen} />
          <Route path="/navcomp" exact component={SelectSectionComp} />
          <Route path="/trendsTable" exact component={TrendsTable} />
          <Route path="/performanceTable" exact component={PerformanceTable} />
          <Route path="/createorganization" exact component={CreateOrganization} />
          {
            newRoute.map((route, idx) => {
              return (
                <AuthMiddleware
                  path={route.path}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              )
            })
          }
          <Route path="/:id" exact component={SignupWorkSpace} />
          <Route path="/" exact component={SignupWorkSpace} />
        </Switch>
      </Suspense>
      <ToastContainer pauseOnHover={false} position="bottom-center" hideProgressBar={true} autoClose={2000} />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  showDashboardOnlyData: selectShowDashboardOnly,
});

export default connect(mapStateToProps)(App)